.popupContainer {
  z-index: 20 !important;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  overflow: hidden;
}
.popupBody {
  position: absolute;
  z-index: 20 !important;
  min-height: 530px;
  max-height: 570px;
  min-width: 600px;
  max-width: 630px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 5vh 3vw 2vh 3vw;
  border-radius: 15px;
  border-color: black;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  background-image: url("../../../assets/backgronds/login/popupBg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
  overflow: hidden;
}
