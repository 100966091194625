.hero_section {
  z-index: 1;
  width: 100%;
  height: calc(90vh - 145px);
  box-sizing: border-box;
  background-color: #000000;
  position: relative;
  margin-top: 145px;
}

.container {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
}

.left {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none;
  position: absolute;
  z-index: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  float: left;
  width: 50%;
  overflow: hidden;
  min-width: 30%;
  max-width: 70%;
  max-height: 100%;
  min-height: 100%;
  height: 100%;
  box-sizing: border-box;
  border: none;
  transition-property: width;
}

.raw_img {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none;
  width: 100vw;
  min-width: 100vw;
  max-width: 100vw;
  position: absolute;
  overflow: hidden;
  left: 0;
  z-index: 1;
}

.middle {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none;
  max-height: 100%;
  min-height: 100%;
  height: 100%;
  width: 5px;
  background-color: inherit;
  cursor: col-resize;
  position: absolute;
  z-index: 1;
}



.right {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: hidden;
  height: 100%;
  box-sizing: border-box;
  border: none;
  background-color: #000000;
  position: relative;
  z-index: 0;
}

.cooked_img {
  position: absolute;
  width: 100vw;
  min-width: 100vw;
  max-width: 100vw;
  left: 0;
  z-index: -1;
}

.raw_nav_div {
  padding-left: 10px;
  box-sizing: border-box;
  width: 100%;
  z-index: 1;
  display: flex;
  gap: 24px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  span {
    cursor: pointer;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    /* identical to box height */

    letter-spacing: 0.01em;
    text-transform: uppercase;

    color: #FFFFFF;
  }
}
.left_section{
  height: 100%;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-end;
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.special_offers_btn {
  font-weight: 700 !important;
  color: #730101 !important;
  cursor: pointer;
  width: 121px !important;
  height: 47px;
  max-height: 47px;
  max-width: 121px !important;
  background: none;
  box-sizing: border-box;
  padding: 0;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  img {
    width: 121px !important;
    height: 47px;
    position: absolute;
    z-index: 0;
    left: 0;
  }

  span {
    overflow-wrap: break-word;
    text-align: start;
    z-index: 1;
    height: 47px;
    display: flex;
    justify-content: center;
    align-items: center;

    font-weight: 500;
    font-size: 16px;
    line-height: 98.9%;
    /* or 16px */

    letter-spacing: 0.01em;
    text-transform: capitalize;

    color: #FFFFFF;

  }
}

.raw_section_heading {
  margin-bottom: 40px;
  cursor: pointer;
  user-select: none;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  z-index: 1;
  div{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
  }

  h2 {
    white-space: nowrap;
    margin: 0;
    font-weight: 800;
    font-size: 48px !important;
    line-height: 59px;
    /* identical to box height */

    letter-spacing: 0.01em;
    text-transform: uppercase;

    color: #FFFFFF;
    z-index: 1;
  }
  hr{

    height: 1px;
    width: 138px;
    background: #FFFFFF;
    position: relative;
  }
}

.cooked_section_heading {
  cursor: pointer;
  user-select: none;
  right: 0;
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  z-index: 2;
  div{
    width: min-content;
    height: min-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 25px;
  }
  h2 {
    z-index: 3;
    width: max-content;
    font-weight: 800;
    font-size: 48px !important;
    line-height: 59px;
    /* identical to box height */

    letter-spacing: 0.01em;
    text-transform: uppercase;

    color: #FFFFFF;
  }
  hr{
    z-index: 3;
    margin: 0;
    height: 1px;
    width: 138px;
    background: #FFFFFF;
  }
}

//////////// mobile //////////////////////

.container_mobile{
  width: 100%;
  height: 90vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-image: url("../../../../assets/backgronds/home/heroSection/mobileBg.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100vh;
}


.left_mobile{
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  width: 50%;
  height: 100%;
}

.search_bar_div{
  position: absolute;
  top: 100px;
  left: 5vw;
}


.right_mobile{
  padding-top: 160px;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  width: 50%;
}

.mobile_background{
 width: 100%;
  height: 90vh;
  position: absolute;
  z-index: -1;
}


.raw_section_heading_mobile {
  margin-bottom: 160px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  div{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
  }

  h2 {
    margin: 0;

    font-weight: 800;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 0.01em;
    text-transform: uppercase;

    color: #FFFFFF;
    white-space: nowrap;

  }
  hr{
    margin: 0;
    height: 1px;
    width: 42px;
    background: #FFFFFF;
  }
}

.cooked_section_heading_mobile {
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  div{
    width: min-content;
    height: min-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 25px;
  }
  h2 {
    width: max-content;
    font-weight: 800;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 0.01em;
    text-transform: uppercase;

    color: #FFFFFF;
  }
  hr{
    margin: 0;
    height: 1px;
    width: 42px;
    background: #FFFFFF;
  }
}