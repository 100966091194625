.cart_section{
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 30px;
  margin-top: 145px;
  padding: 100px 7vw 120px ;
  background-image: url("../../../../assets/backgronds/checkout/checkoutBg.webp");
  background-size: 100vw;

}

.first_section{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 40px;
}
.section_heading {
  position: relative;
  left: -7vw;
  display: flex;
  flex-direction: row;
  align-items: flex-end;

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
  }

  h2 {
    margin: 0;
    font-weight: 800;
    font-size: 48px !important;
    line-height: 59px;
    /* identical to box height */

    letter-spacing: 0.01em;
    text-transform: uppercase;

    color: #FFFFFF;
    z-index: 1;
  }

  hr {

    height: 1px;
    width: 138px;
    background: #FFFFFF;
    position: relative;
    left: -10px;
  }
}

.order_heading{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;

  white-space: nowrap;
  font-weight: 500;
  font-size: 22px;
  line-height: 120%;
  /* or 26px */

  letter-spacing: 0.01em;
  text-transform: capitalize;

  color: #201E1E;
  margin-bottom: 30px;
}
.order_form_heading{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;

  white-space: nowrap;
  font-weight: 800;
  font-size: 36px;
  line-height: 44px;
  /* identical to box height */

  letter-spacing: 0.01em;

  color: #FFFFFF;
  margin-bottom: 30px;
}
.main_order_section{
  width: 100%;
  display: flex;
  gap: 5vw;
  margin-bottom: 120px;
}


.payment_method_section{
  padding: 5vw;
  box-sizing: border-box;
  width: 40%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 30px;

  background: #FFFFFF;
  border: none;
  box-shadow: -2px 4px 4px rgba(255, 255, 255, 0.25);
  border-radius: 10px
}

.cash_less_idram_div{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 30px;

  label{
    margin-top: -30px;
  }
  img{
    height: 21px;
    cursor: pointer;
  }
}

.radio_margin{
  margin-bottom: 30px;
}

.radio_btn{
  display: block;
  cursor: pointer;
  user-select:none;
  text-align: left;
  input{
    display: none;
    &+span{
      display: inline-block;
      position: relative;
      padding-left: 30px;

      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: 0.01em;
      text-transform: capitalize;

      color: #000000;

      &:before{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 50%;
        display: flex;
        font-size: 0;
        justify-content: center;
        align-items: center;
        width: 20px !important;
        height: 20px !important;
        max-width: 20px;
        min-width: 20px;
        background: #FFFFFF;
        border: 1px solid rgba(0, 0, 0, 0.2);
      }
      &:after{
        position: absolute;
        top: 5.8px !important;
        left: 5.8px !important;
        content: '';
        display: flex;
        width: 10px !important;
        height: 10px !important;
        background: #223D40;

        border-radius: 50%;

        opacity: 0;
        transform: scale(0,0);
        transition: all .2s cubic-bezier(0.64, 0.57, 0.67, 1.53);
      }
    }
    &:checked+span:after{
      opacity: 1;
      transform: scale(1,1);
    }
  }
}

.icon_div{
  margin-top: 20px;
  display: flex;
  justify-content: flex-start;
  gap: 15px;
}

.order_form{
  padding: 4vw;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;

  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}
.row_inputs_div{
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 50px;
}

.tel_input_div{
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;

  padding:0 15px;
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #000000;
  border-radius: 5px;

  input {
    border: none !important;
    padding: 15px 15px 15px 5px !important;
  }
}

.tel_span{
 color: #000000 !important;
}

.input_div{
  width: 100%;
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  letter-spacing: 0.02em;
  text-transform: capitalize;

  color: #000000;


  label{
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */

    letter-spacing: 0.02em;
    text-transform: capitalize;

    color: #730101;
  }
  span{
    color: #9B1717;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {

    z-index: 1 !important;
    -webkit-text-fill-color: #000000 !important;
    caret-color: white;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height, or 117% */

    color: #000000 !important;
    -webkit-box-shadow: none;
    transition: background-color 5000s ease-in-out 0s;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
  input{
    box-sizing: border-box;
    padding: 15px;
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #000000;
    border-radius: 5px;

    color: #000;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.28px;
  }
  textarea{
    outline: none;
    resize: none;
    box-sizing: border-box;
    background: #FFFFFF;
    border: 1px solid #000000;
    border-radius: 5px;
    width: 100% !important;
    height: 100px !important;
    padding: 15px;
  }
}

.checkout_btn{
  width: 100%;
  max-width: 168px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  border: none;
  background: #730101;
  border-radius: 5px;
  padding: 15px calc(15px + 2vw) 15px calc(15px + 2vw);


  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  color: #FFFFFF;
}

.region_dropdown_toggle{
  cursor: pointer;
}

.region_dropdown{
  position: absolute;
  background: #730101;
}

.input_date_div {
  width: 100%;
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  label{
    font-size: 17px;
    line-height: 20px;
    letter-spacing: 0.02em;
    text-transform: capitalize;

    color: #663B20;
  }
}

.checkout_div{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.total_price{
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  gap: 10px;
  span{
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.02em;
    text-transform: capitalize;

    color: #730101;

  }

  font-weight: 500;
  font-size: 17px;
  line-height: 21px;
  /* identical to box height */

  letter-spacing: 0.02em;
  text-transform: capitalize;

  color: #000000;

}

//mobile version ////

.main_order_section_mobile{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.payment_method_section_mobile{
  padding: 15px;
  box-sizing: border-box;
  height: fit-content;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 30px;

  background: #F3F3F3;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}

.order_form_mobile{
  padding: 15px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;

  background: #F3F3F3;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}

.cursor_loading{
  cursor: wait;
}

.delivery_price{
  display: flex;
  justify-content: center;
  align-items: center;



  font-weight: 700;
  font-size: calc(10px + 0.5vw);
  line-height: 20px;
  letter-spacing: 0.01em;
  text-transform: capitalize;

  color: #FFFFFF;
}

.delivery_icon{
  max-width: 122px;
  width: 10vw;
}