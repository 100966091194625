.main_section{
  width: 100%;
  box-sizing: border-box;
  margin-top: 120px;
}

.section_question{
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.comment_input_heading{
  font-weight: 800 !important;
  font-size: 18px !important;
  line-height: 22px !important;
  /* identical to box height */

  letter-spacing: 0.01em !important;
  text-transform: uppercase !important;

  color: #FFFFFF;
}

.review_centered_section {
  width: 100%;

  box-sizing: border-box;
  padding: 0 7vw;
  border: none;
  margin-top: 60px;
  overflow: hidden;

  h2 {
    margin: 0;
    padding: 0;
    font-weight: 800;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */

    letter-spacing: 0.01em;
    text-transform: uppercase;

    color: #FFFFFF;

  }

  h3 {
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    /* identical to box height */

    letter-spacing: 0.01em;
    text-transform: capitalize;

    color: #FFFFFF;

  }
}

.section_heading {
  display: flex;
  flex-direction: row;
  align-items: flex-end;

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
  }

  h2 {
    margin: 0;

    font-weight: 800;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 0.01em;
    text-transform: uppercase;

    color: #FFFFFF;

    z-index: 1;
  }

  hr {

    height: 1px;
    width: 42px;
    background: #FFFFFF;
    position: relative;
  }
}

.swiper {
  margin-top: 35px !important;
  box-sizing: border-box;
  width: 90% !important;
  overflow-x: hidden !important;
  height: min-content !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.heading_btn_div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.swiper_div {
  width: 100%;
  display: flex;
  justify-content: space-between;
}


.sliderDiv {
  height: min-content;
  border: none !important;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  max-width: 360px;
  width: 24vw;
  background: none;

  p {
    max-width: 253px;
    height: auto;
    overflow-x: auto;
    margin: 35px 0 20px 0;
    padding: 0;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    letter-spacing: 0.01em;
    text-transform: uppercase;

    color: #FFFFFF;
  }
}

.username {
  margin-top: 10px;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.01em;
  text-transform: uppercase;

  color: #FFFFFF;
}

.date{
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.01em;
  text-transform: uppercase;

  color: #FFFFFF;

}

.swiper_nav_btn {
  cursor: pointer;
  display: flex !important;
  justify-content: center !important;
  align-items: center;
  padding: 0 !important;
  border: none !important;
  background: none;
}


.swiper_btn_div {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}

.comment_input {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.plate_img{
  width: 106px;
}
