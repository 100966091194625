.main_section_div {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 0 7vw;
}

.main_section_div_popup {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 0 7vw;
}


.login_section {
  max-width: 457px;
  min-width: 328px;
  width: 35vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;

}

.heading_div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.heading_div_popup {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.back_btn {
  position: absolute;
  left: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 9px;
  background: none;
  border: none;
  margin-left: 7vw;
  span {
    height: min-content;
    font-weight: 500;
    font-size: 16px;
    /* identical to box height */

    letter-spacing: 0.01em;
    text-transform: capitalize;

    color: #FFFFFF;
  }
}

.back_btn_popup{
  position: absolute !important;
  top: 36px;
  right: 24px !important;
  left: unset !important;
  margin: 0 !important;
}
.position_relative{
  position: relative !important;
  float: left;
}
.heading {
  font-weight: 800;
  font-size: 48px;
  line-height: 28px;
  /* or 58% */

  display: flex;
  align-items: center;

  color: #FFFFFF;

}

.heading_popup_font {
  color: #730101
}

.loginInput {
  width: 100%;
  min-height: 60px;
  height: 7vh;
  margin-top: 10px;
  background: none;
}

.registration_input {
  height: 50px !important;
}

.label {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  /* identical to box height, or 117% */

  display: flex;
  align-items: center;

  color: #FFFFFF;
}


.label_popup_font {
  color: #000000;
}


.inputdiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  margin: 0;
  gap: 7px;
}

//.tel_input{
//    width: 40%;
//}
//.email_input{
//    width: 60%;
//}
.row_input_div {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.login_btn {
  border: none;
  background: none;
}

.recover_password {
  cursor: pointer;
  background: none;
  border: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height, or 121% */

  display: flex;
  align-items: center;

  color: #730101;

}

.forgot_div {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.plate_on_flour {

  display: flex;
  justify-content: center;
  align-items: center;
  width: 190px;
  height: 179px;
  margin: 0;
  padding: 0;
  box-sizing: border-box;

}

.flour_img {
  width: 190px;
  height: 179px;
  background-image: url("../../../assets/backgronds/login/flour.png");
  background-repeat: no-repeat;
  background-size: 190px 179px;
}

.plate_img {
  cursor: pointer;
  width: 131.55px;
  height: 130.28px;
  position: absolute;
  z-index: 1;
  transition-delay: 1000ms;
  background-image: url("../../../assets/backgronds/home/statsSection/plate1.webp");
  background-repeat: no-repeat;
  background-size: 131.55px 130.28px;

  -webkit-animation-name: spin;
  -webkit-animation-duration: 4000ms;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;

  -moz-animation-name: spin;
  -moz-animation-duration: 4000ms;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;

  -ms-animation-name: spin;
  -ms-animation-duration: 4000ms;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;

  animation-name: spin;
  animation-duration: 4000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  @-ms-keyframes spin {
    from {
      -ms-transform: rotate(0deg);
    } to {
        -ms-transform: rotate(360deg);
      }
  }
  @-moz-keyframes spin {
    from {
      -moz-transform: rotate(0deg);
    } to {
        -moz-transform: rotate(360deg);
      }
  }
  @-webkit-keyframes spin {
    from {
      -webkit-transform: rotate(0deg);
    } to {
        -webkit-transform: rotate(360deg);
      }
  }
  @keyframes spin {
    from {
      transform: rotate(0deg);
    } to {
        transform: rotate(360deg);
      }
  }
  transition-timing-function: ease-in-out;

  transform: rotate(0deg);
}
.plate_img:hover {
  transition-delay: 1000ms;
  transition-timing-function: ease-in-out;
  animation: rotation 2s infinite linear;
  transform: rotate(50deg);
}

.login_text {
  pointer-events: none;
  cursor: pointer;
  position: absolute;
  z-index: 3;

  font-weight: 700;
  font-size: 25px;
  line-height: 30px;
  letter-spacing: 0.01em;
  text-transform: capitalize;

  color: #FFFFFF;

}

.login_popup_btn_mobile{
  cursor: pointer;
  border: none;
  background: #730101;
  border-radius: 5px;

  box-sizing: border-box;

  padding: 14px 26px;
  min-width: 150px;

  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  /* identical to box height, or 156% */

  display: flex;
  align-items: center;
  text-align: center;
  text-transform: capitalize;

  color: #FFFFFF;
}
.login_popup_btn {
  cursor: pointer;
  border: none;
  background: #730101;
  border-radius: 5px;

  box-sizing: border-box;

  padding: 9px 26px;

  font-weight: 600;
  font-size: 16px;
  line-height: 25px;

  display: flex;
  align-items: center;
  text-align: center;

  color: #FFFFFF;

}

.register_btn {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  display: flex;
  align-items: center;

  color: #FFFFFF;


  button {
    cursor: pointer;
    border: none;
    background: none;
    /* identical to box height, or 22px */
    font-size: 14px;
    line-height: 20px;
    font-weight: 800 !important;
    color: #730101;
  }
}


///////////////////////////////////////////mobile///////////////////////////////////
.main_section_div_mobile {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 0 5vw;
}

.login_section_mobile {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;

}
.back_btn_div_mobile{
  margin-top: 50px;
  width: 100%;
}

.no_margin{
  margin: 0 !important;
}
.heading_mobile {
  font-weight: 800;
  font-size: 40px;
  line-height: 28px;
  /* identical to box height, or 70% */

  display: flex;
  align-items: center;

  color: #FFFFFF;
}

.heading_mobile_ru{
  font-weight: 800;
  font-size: 23px;
  line-height: 40px;
  /* identical to box height, or 70% */

  display: flex;
  align-items: center;

  color: #FFFFFF;
}

.label_mobile {
  font-weight: 400;
  font-size: 15px;
  line-height: 21px;
  /* identical to box height, or 141% */

  display: flex;
  align-items: center;

  color: #FFFFFF;

}

.recover_password_mobile {
  background: none;
  border: none;

  font-weight: 400;
  font-size: 15px;
  line-height: 21px;
  /* identical to box height, or 141% */

  display: flex;
  align-items: center;

  color: #FFFFFF;
}

.plate_on_flour_mobile {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 149px;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.flour_img_mobile {
  width: 160px;
  height: 149px;
  background-image: url("../../../assets/backgronds/login/flour.png");
  background-repeat: no-repeat;
  background-size: 160px 149px;
}

.plate_img_mobile {
  width: 106.94px;
  height: 105.9px;
  position: absolute;
  z-index: 1;
  background-image: url("../../../assets/backgronds/home/statsSection/plate1.webp");
  background-repeat: no-repeat;
  background-size: 106.94px 105.9px;

  -webkit-animation-name: spin;
  -webkit-animation-duration: 4000ms;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;

  -moz-animation-name: spin;
  -moz-animation-duration: 4000ms;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;

  -ms-animation-name: spin;
  -ms-animation-duration: 4000ms;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;

  animation-name: spin;
  animation-duration: 4000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  @-ms-keyframes spin {
    from {
      -ms-transform: rotate(0deg);
    } to {
        -ms-transform: rotate(360deg);
      }
  }
  @-moz-keyframes spin {
    from {
      -moz-transform: rotate(0deg);
    } to {
        -moz-transform: rotate(360deg);
      }
  }
  @-webkit-keyframes spin {
    from {
      -webkit-transform: rotate(0deg);
    } to {
        -webkit-transform: rotate(360deg);
      }
  }
  @keyframes spin {
    from {
      transform: rotate(0deg);
    } to {
        transform: rotate(360deg);
      }
  }
  transition-timing-function: ease-in-out;

  transform: rotate(0deg);
}
.plate_img_mobile:hover {
  transition-delay: 1000ms;
  transition-timing-function: ease-in-out;
  animation: rotation 2s infinite linear;
  transform: rotate(50deg);
}
.login_text_mobile {
  pointer-events: none;
  cursor: pointer;
  position: absolute;
  z-index: 3;

  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-transform: capitalize;

  color: #FFFFFF;
}

.register_btn_mobile {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  /* identical to box height, or 143% */

  display: flex;
  align-items: center;

  color: #FFFFFF;
  text-wrap: none;
  white-space: nowrap;

  button {
    cursor: pointer;
    border: none;
    background: none;
    /* identical to box height, or 22px */
    font-size: 12px;
    line-height: 20px;
    font-weight: 800 !important;
    color: #730101;
  }
}