.main_section{
  margin-top: 120px;
  width: 100%;
  height: min-content;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-bottom: 30px;
  box-sizing: border-box;
  background-image: url("../../../../assets/backgronds/home/faqSection/faqBackgroundImg.webp");
  background-repeat: no-repeat;
  background-size: 100vw;
}

.without_background{
 background: none !important;
}


.faq_background{
  width: 100%;
  position: absolute;
}
.section_heading {
  margin-bottom: 75px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  div{
    width: min-content;
    height: min-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 25px;
  }
  h2 {
    margin: 0;
    width: max-content;
    font-weight: 800;
    font-size: 48px !important;
    line-height: 59px;
    /* identical to box height */

    letter-spacing: 0.01em;
    text-transform: uppercase;

    color: #FFFFFF;

    text-align: end;
    white-space: break-spaces;
  }
  hr{
    margin: 0;
    height: 1px;
    width: 138px;
    background: #FFFFFF;
  }
}

.section_heading_mobile{
  margin-bottom: 25px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  div{
    width: min-content;
    height: min-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 25px;
  }
  h2 {
    margin: 0;
    width: max-content;
    font-weight: 800;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 0.01em;
    text-transform: uppercase;

    color: #FFFFFF;

    text-align: end;
    white-space: break-spaces;

  }
  hr{
    margin: 0;
    height: 1px;
    width: 42px;
    background: #FFFFFF;
  }
}

.question_section{
  width: 100%;
  display: flex;
  gap:50px;
  justify-content: space-between;
  padding: 0 7vw;
  box-sizing: border-box;

}

.question_section_two_divs{
  display: flex;
  flex-direction: column;
  gap: 250px;
}

.second_question_div{
  margin-top: 160px;
}