.dropdown_mobile{
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dropdown{
  width: 100%;
  box-sizing: border-box;
}
.dropdown__toggle_mobile{
  width: 100%;
  max-width: 300px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
  padding: 18px 25px;
  box-sizing: border-box;

  background: linear-gradient(338.24deg, rgba(255, 255, 255, 0.8) -1.32%, #FFFFFF -1.31%, #FFFFFF 109.86%);
  border-radius: 5px;
  border:none;

  p {
    margin: 0;
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.01em;
    text-transform: uppercase;

    color: #000000;

  }
}

.minus_mobile{
  width: 14.61px;
  height: 2.07px;
}

.plus_mobile{
  width: 14.53px;
  height: 15.92px;
}

.dropdown__toggle{
  width: 40vw;
  max-width: 489px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
  padding: 18px 25px;
  box-sizing: border-box;

  background: linear-gradient(338.24deg, rgba(255, 255, 255, 0.8) -1.32%, #FFFFFF -1.31%, #FFFFFF 109.86%);
  border-radius: 5px;
  border:none;
  p{
    margin: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    text-align: left;

    color: #000000;

  }

}
.dropdown__paragraph__div{
  position: absolute;
  width: 40vw;
  max-width: 489px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 5px;
  box-sizing: border-box;
  padding: 15px 24px;
  margin-top: -5px;
  p{
    white-space: pre-line;
    margin: 0;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.01em;

    color: #730101;

  };
}

.dropdown__paragraph__div_mobile{
  width: 100%;
  max-width: 300px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 5px;
  box-sizing: border-box;
  padding: 15px 24px;
  margin-top: -5px;
  p{
    white-space: pre-line;
    margin: 0;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.01em;

    color: #730101;

  };
}