
.customInput:-webkit-autofill,
.customInput:-webkit-autofill:hover,
.customInput:-webkit-autofill:focus,
.email_input:-webkit-autofill,
.email_input:-webkit-autofill:hover,
.email_input:-webkit-autofill:focus {

  z-index: 1 !important;
  -webkit-text-fill-color: #000000 !important;
  caret-color: black;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  /* identical to box height, or 117% */

  color: #000000 !important;
  -webkit-box-shadow: none;
  transition: background-color 5000s ease-in-out 0s;
}


.email_input {
  text-transform: lowercase;
  box-sizing: border-box;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  /* identical to box height, or 117% */

  display: flex;
  align-items: center;

  color: #000000;


  padding: 0 10px 0 10px;
  /* identical to box height */
  margin: 0;
  letter-spacing: 0.02em;
  font-size: 18px;
  background: none;
  border: none;
  border-radius: 5px;
}

.email_input:focus {
  outline: none !important;
}

.customInput::-webkit-outer-spin-button,
.customInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.customInput[type=number] {
  -moz-appearance: textfield;
}

.customInput {
  box-sizing: border-box;

  padding: 0 10px 0 10px;
  margin: 0;
  z-index: 1 !important;
  position: relative;
  background: none;
  border: none;
  width: 100%;
  height: 100%;


  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  /* identical to box height, or 117% */

  display: flex;
  align-items: center;

  color: #000000;

}


.customInputDiv {
  width: 100%;
  height: min-content;
  z-index: 1 !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 10px 0 0;

  box-sizing: border-box;

  background: none;
  border: 1px solid #000000;
  border-radius: 5px;

  span {
    cursor: pointer;
  }
}

.customInput:focus {
  outline: none !important;
}
