

@font-face {
  font-family: 'Mardoto';
  src: local('Mardoto'), url(./fonts/Mardoto/Mardoto-Black.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Mardoto';
  src: local('Mardoto'), url(./fonts/Mardoto/Mardoto-Bold.ttf) format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Mardoto';
  src: local('Mardoto'), url(./fonts/Mardoto/Mardoto-Light.ttf) format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Mardoto';
  src: local('Mardoto'), url(./fonts/Mardoto/Mardoto-LightItalic.ttf) format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Mardoto';
  src: local('Mardoto'), url(./fonts/Mardoto/Mardoto-Medium.ttf) format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Mardoto';
  src: local('Mardoto'), url(./fonts/Mardoto/Mardoto-Regular.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


:root {
  --app-fontFamily: 'Mardoto';
}

img{
  border-style: none;
}

button,
textarea,
input,
select,
label,
radio,
a{
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

}

html {
  height: 100%;
}

*{
  font-family: var(--app-fontFamily);
  font-style: normal;
}

body{
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0;
  min-height: 100vh;
  font-style: normal;
  overflow-x: hidden !important;
  background-color: #000000;
  color: #FFFFFF;
  box-sizing: border-box;
  padding: 0;
}

main {
  flex: 1;
}

input:focus {
  outline: none;
}

button {
  outline: none;
}
label:hover,
label:focus-within {
  outline: none;
}