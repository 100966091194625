.catalog_navbar {
  z-index: 4;
  display: flex;
  width: 100%;
  position: absolute;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: none;
}

.without_image_navbar {
  z-index: 2;
  display: flex;
  width: 100%;
  position: absolute;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: none;
  transition-duration: 700ms;
}
.without_image_navbar_scrolled {
  z-index: 10;
  display: flex;
  width: 100%;
  position: fixed;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.70);
  backdrop-filter: blur(2px);
  transition-duration: 700ms;
}
.navbar_section {
  height: 145px;
  box-sizing: border-box;
  padding: 54px 7vw 35px 7vw !important;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  transition-duration: 700ms;
}

.navbar_section_scrolled {
  height: 85px;
  box-sizing: border-box;
  padding: 20px 5vw 20px 5vw !important;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  transition-duration: 700ms;
}

.contact_section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;

  transition-duration: 700ms;
  color: rgba(255, 255, 255, 0.8);
  user-select: none;
  p{
    margin: 0;
    padding: 0;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.01em;
    text-transform: capitalize;
    transition-duration: 700ms;
  }
  img{
    width: 19px;
    transition-duration: 700ms;
  }
}
.contact_section_scrolled {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
  user-select: none;
  transition-duration: 700ms;
  p{
    margin: 0;
    padding: 0;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.01em;
    text-transform: capitalize;
    color: rgba(255, 255, 255, 0.8);
    transition-duration: 700ms;
  }
  img{
    width: 15px;
    transition-duration: 700ms;
  }
}

.nav_section_first_div {
  width: 20vw;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  justify-content: flex-start;
}

.navbar_logo{
  cursor: pointer;
  width: 278px;
  user-select: none;
  transition-duration: 700ms;

}
.navbar_logo_scrolled{
  cursor: pointer;
  width: 200px;
  user-select: none;
  transition-duration: 700ms;

}
.nav_section_second_div {
  height: 44px;
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: center;
}


.second_section_first_btn {
  cursor: pointer;
  border: none;
  background: none;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  letter-spacing: 0.01em;
  text-transform: capitalize;

  color: #FFFFFF;
}

.catalogBtn {
  cursor: pointer;
  background: rgba(19, 56, 72, 0.06);
  border: 1px solid #FFFFFF !important;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.01em;

  color: #FFFFFF;
  border-radius: 10px;
  width: 9vw;
  height: 5vh;
  min-width: 120px;
  min-height: 40px;
  padding: 30px 0 30px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 1vw;
  justify-content: center;
  align-items: center;
}

.second_section_second_btn {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  gap: 5px;

}

.btn_icon_div {
  width: 3vw;
  height: 3vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: 25px;
  min-height: 25px;
  background: none;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 10px;
}

.for_count_icon_flex_div {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.item_quantity {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  position: absolute;
  background: #730101;
  border-radius: 50%;
  margin: -10px;

  font-weight: 700;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.01em;
  text-transform: capitalize;

  color: #FFFFFF;
}

.login_btn {
  cursor: pointer;
  border: 1px solid #FFFFFF;
  border-radius: 5px;
  background: none;

  width: 91px;
  height: 44px;
  font-weight: 700;
  font-size: 14px;
  /* identical to box height */

  letter-spacing: 0.01em;
  text-transform: uppercase;

  color: #FFFFFF;


  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 0;
}
.logged_btn{
  cursor: pointer;
  width: 44px;
  height: 44px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: none;

  border: 1px solid #FFFFFF;
  border-radius: 5px;

}
.my_profile_div {
  padding: 0;
}

.dropdown_menu {
  width: 150px;
  box-sizing: border-box;
  z-index: 4;
  margin-top: 14px;
  position: absolute;
  padding: 12px 10px;
  display: flex;
  flex-direction: column;
  background: #000000;
  border: 1px solid #FFFFFF;
  border-radius: 5px;

  gap: 10px;

  button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    border: none;
    background: none;
    cursor: pointer;

    span {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      /* identical to box height */
      text-transform: capitalize;
      letter-spacing: 0.01em;

      color: #FFFFFF;

    }
  }

}

.lang_btn {
  width: 73px;
  box-sizing: border-box;
  padding: 7.5px;
  border: none;
  background: #730101;
  border-radius: 3px;

  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  span {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    letter-spacing: 0.01em;
    text-transform: capitalize;

    color: #FFFFFF;

  }

}

.lang_dropdown_menu {
  margin-top: 7px;
  width: 73px;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  background: none;
  border: none;
  gap: 6px;
  button {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
    background: none;
    border: none;
    padding: 0;

    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    letter-spacing: 0.01em;
    text-transform: capitalize;

    color: #FFFFFF;

  }
}
