.signin_section{
    width: 100%;
    height: 100vh;
    gap: 80px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    background-image: url("../../../assets/backgronds/login/loginBackground.webp");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
}
.signin_section_mobile{
    width: 100%;
    gap: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    background-image: url("../../../assets/backgronds/login/loginBackgroundMobile.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.img_div{
    width: 50%;
    img{
        height: 70vh;
        width: 100%;
    }
}

.section_div_mobile{
    width: 100%;
}