.dropdown{
  box-sizing: border-box;
  cursor: pointer;
  width: 100% !important;
  background: none;
  border-radius: 5px;
  border: none;
  padding:  1px ;
}

.dropdown_toggled_btn{
  background: #730101;
  border-radius: 5px 5px 0 0!important;

  img{
    background: #FFFFFF !important;
  }
  label{

    color: #FFFFFF !important;
  }
}

.toggle_icon{
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;

  background: #730101;
  border-radius: 5px;
}

.toggle_icon_dropped{
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;

  background: #FFFFFF;
  border-radius: 5px;
}
.dropdown__toggle{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  box-sizing: border-box;
  border: none;
  border-radius: 5px ;

  padding: 15px;

  img{


  }
  label{
    font-weight: 600;
    font-size: 20px;
    line-height: 120%;
    /* identical to box height, or 24px */

    letter-spacing: 0.03em;
    text-transform: uppercase;

    color: #730101;
  }
}

.dropdown__option__div{
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1px;
  border: 1px solid #FFFFFF;
  border-radius: 0 0 5px 5px;
}

.dropdown_option{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  padding: 7px 15px;
  background: none;
  border-bottom: 1px solid #FFFFFF;
  gap: 17px;


  span {
    font-weight: 400;
    font-size: 15px;
    line-height: 120%;
    /* identical to box height, or 18px */

    letter-spacing: 0.04em;
    text-transform: capitalize;

    color: #FFFFFF;

  }
}

.dropdown_last_option{
  border-radius: 0 0 5px 5px;
  border: none;
}

.checkbox  {
  cursor: pointer;
  outline: none;
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  accent-color: #730101;
  padding-right: 0;
  margin: 0;
}
.checkbox:checked ~ .checkmark {
  background: white;
}

.up_side_icon{
  transform: rotate(180deg);
}