.slider_container {
  margin-top: -55px;
  box-sizing: border-box;
  position: relative;

}

.slider_container_mobile {
  margin-top: -150px;
  overflow: hidden !important;
  box-sizing: border-box;
  position: relative;
}
